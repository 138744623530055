* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  border: none;
  scroll-behavior: smooth;
  font-family: 'Montserrat' !important;
  scroll-behavior: smooth;
}

body {
  background: #F8F8F8 !important;
}

a {
  text-decoration: none;
}

a:visited {
  color: unset;
}

button {
  background: none;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
  font-style: normal;
  font-display: swap;
}